/* TODO: Comments are off: Used CSS to SASS convertor that didn't move comments with code blocks. */

/* A few mobile classes on the body and viewport.  */

body.flipped
  background: #000

.viewport
  &.flip
    -webkit-transition-property: -webkit-transform
    -webkit-transform: rotateY(180deg)
    -webkit-transition-duration: 750ms
    -webkit-transition-timing-function: ease-in-out
    -webkit-transition-delay: 0s
    -webkit-backface-visibility: hidden
  &.back
    -webkit-transform: rotateY(-180deg)
    -webkit-backface-visibility: hidden
    position: fixed
    left: 0px
    top: 0px
    right: 0px
    bottom: 0px
    width: auto
    height: auto
    &.flip
      -webkit-transform: rotateY(0deg)
  .backscreen
    background: rgba(0, 0, 0, 0.8)
    position: absolute
    left: 0px
    top: 0px
    right: 0px
    bottom: 0px
    width: auto
    height: auto
    overflow: hidden

.board
  position: relative
  left: 0px
  top: 0px
  width: 100%
  height: 100%
  background-repeat: repeat
  background-position: center center
  margin: 0
  padding: 0
  border: 0
  overflow: visible
  z-index: 1
  -webkit-transform-style: flat
  -moz-transform-style: flat
  -webkit-transform: scale(1)
  -moz-transform: scale(1)
  transform: scale(1)
  .drag-window
    position: relative
    left: 0px
    top: 0px
    width: 100%
    height: 100%

/* Note that this is shared by the cork and the map.
 * Probably shouldn't be. */

.center
  position: absolute
  overflow: visible
  width: 1px
  height: 1px
  z-index: 2
  /*-webkit-transform-style: flat;
   *-moz-transform-style: flat;
   *-webkit-transform: matrix(1,0,0,1,0,0);
   *-moz-transform: matrix(1,0,0,1,0,0);
   *transform: matrix(1,0,0,1,0,0);
   */

/* RGBA fix for IE */

.background-rgba-fix
  position: absolute
  left: 0px
  top: 0px
  width: 100%
  height: 100%
  z-index: -1
  /* Background color needs to be specified by more specific definitions. */

/* Widely used controls. */

.control.url
  border-top: none
  border-right: none
  border-left: none
  border-bottom: dotted 1px white
  background: none
  color: #FFFFFF
  font-weight: normal
  font-size: 13px
  text-shadow: 1px 1px 1px #000

/* Rich Textarea */

.rich-textarea
  body
    border: solid 1px red
  *
    white-space: pre-wrap
  div
    display: inline
  a
    color: blue
    cursor: pointer
    &:hover
      cursor: pointer
  ul
    list-style-type: disc
    padding-left: 20px
    ul
      list-style-type: circle
  ol
    ul
      list-style-type: circle
    ol ul, ul ul
      list-style-type: square
  ul
    ol ul, ul ul
      list-style-type: square
  ol
    list-style-type: decimal
    padding-left: 20px

/* Let's be paranoid. */

/* Default text box. */

.default-text-box
  border-top: none
  border-right: none
  border-left: none
  border-bottom: dotted 1px white
  background: none
  color: #FFFFFF
  font-weight: normal
  font-size: 13px
  text-shadow: 1px 1px 1px #000
  padding-left: 0px
  padding-right: 0px
  cursor: text
  &.empty
    color: #666 !important
    font-style: italic
  &.disabled
    border-bottom: none
    color: white

/* "Global" */

.no-user-select
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: -moz-none
  -ms-user-select: none
  -o-user-select: none
  user-select: none
  *
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: -moz-none
    -ms-user-select: none
    -o-user-select: none
    user-select: none

.user-select
  -webkit-user-select: text
  -khtml-user-select: text
  -moz-user-select: text
  -ms-user-select: text
  -o-user-select: text
  user-select: text
  *
    -webkit-user-select: text
    -khtml-user-select: text
    -moz-user-select: text
    -ms-user-select: text
    -o-user-select: text
    user-select: text

.move-cursor:hover
  cursor: move
  *
    cursor: move

.resize-cursor:hover
  cursor: se-resize
  *
    cursor: se-resize

/* Thank you */

.notice
  .thankyou
    padding: 33px
    h1
      font-size: 24px
  &.getupdates
    .body
      padding: 18px 33px 33px
      width: 454px
    input
      font-size: 18px
      height: 25px
      width: 340px
      margin-right: 10px
    .cta-button
      width: 80px
    .icon
      height: 20px
  &.registration-modal .body
    padding: 0px

.header .secure-header
  font-weight: normal
  font-size: 13px
  color: white
  padding-left: 40px
  background-position: left center
  background-repeat: no-repeat
  /*margin-left: 10px; */
  opacity: 0.4
  padding-top: 7px
  padding-bottom: 5px

.notice
  .register
    width: 540px
    padding: 13px 23px 23px 23px
    text-align: left
    .pricing, .login-info
      padding-bottom: 70px
    .page-wrapper
      width: 100%
      /*height: 100%; */
    .loading
      background-position: center center
      background-repeat: no-repeat
      width: 100%
      height: 348px
    .finished
      height: 280px
    .copy
      margin-left: 40px
      margin-bottom: 20px
      margin-top: 10px
      line-height: 15px
    .pricing
      ul
        margin-top: 15px
        margin-bottom: 20px
      li
        background-position: left top
        background-repeat: no-repeat
        padding-left: 15px
        line-height: 23px
        list-style-type: none
        list-style-position: inside
        font-size: 14px
      .image-wrapper
        width: 243px
        height: 217px
        margin-right: 9px
        margin-top: 17px
        float: right
      .image
        background: url("../images/modals/pricing-image.png") left top no-repeat
        width: 100%
        height: 100%
        -webkit-box-shadow: inset 0px 0px 15px black
        -moz-box-shadow: inset 0px 0px 15px black
        -ms-box-shadow: inset 0px 0px 15px black
        box-shadow: inset 0px 0px 15px black
        -webkit-border-radius: 10px
        -moz-border-radius: 10px
        -ms-border-radius: 10px
        border-radius: 10px
    .finished
      text-align: center
    input
      &[type=text], &[type=password]
        font-size: 16px
        padding: 5px
        width: 300px
    label
      width: 150px
    h1
      font-size: 20px
      padding-bottom: 12px
      background-position: left center
      background-repeat: no-repeat
      display: block
      padding-top: 20px
      padding-left: 13px
      width: 100%
    .pricing h1.price
      display: inline-block
      float: right
      padding-right: 20px
    .login-info label, .referral label
      margin-left: 15px
    h1.thank-you
      font-size: 24px
      padding-left: 0px
      padding-top: 55px
    .finished .copy
      margin-left: 0px
      margin-bottom: 20px
      margin-top: 15px
    .selection-div
      cursor: pointer
      line-height: 16px
      padding-left: 35px
      margin-top: 14px
      margin-bottom: 14px
      *
        vertical-align: middle
        display: inline-block
      input[type="radio"]
        position: relative
        top: -2px
        margin-right: 5px
    .url
      width: 495px !important
      /* Why does this need to be important?? */
      margin-left: 15px
      margin-top: 10px
    .go-to-cork
      display: table
      margin-left: auto
      margin-right: auto
    .billing-frame
      width: 100%
      height: 294px
    .message
      line-height: 15px
      font-size: 13px
      font-weight: normal
      background-position: left center
      background-repeat: no-repeat
      margin-left: 10px
      padding-bottom: 15px
      padding-right: 10px
      padding-left: 7px
      padding-top: 5px
      margin-top: 20px
    .no-thanks
      position: absolute
      bottom: 33px
      right: 33px
      text-align: right
      color: #888
      padding-bottom: 6px
      cursor: pointer
      .title
        font-size: 14px
        font-weight: bold
        padding-bottom: 3px
        color: #DDD
        text-shadow: 2px 2px 2px black
    .choose-period
      .period
        position: relative
        margin-left: 13px
        padding: 20px
        background: #AFAEA8
        text-shadow: 1px 1px 1px #888
        color: white
        margin-top: 10px
        margin-bottom: 10px
        cursor: pointer
        -webkit-border-radius: 10px
        -moz-border-radius: 10px
        -ms-border-radius: 10px
        border-radius: 10px
        &.yearly
          background: #2A6FB4
          margin-bottom: 13px
          text-shadow: none
        h2
          display: inline-block
        &.selected
          background: #2A6FB4
          text-shadow: none
        &.deselected
          background: #AFAEA8
          text-shadow: 1px 1px 1px #999
      .message
        padding-bottom: 2px
      .cta-button
        position: absolute
        top: 11px
        right: 15px
        width: 124px
      .savings
        font-weight: bold
        text-decoration: underline
  &.login
    .body
      width: 280px
      padding: 17px 23px 23px 23px
      text-align: left
    input
      &[type=text], &[type=password]
        width: 100%
        font-size: 13px
    .password, .security-code, .password-confirmation
      margin-top: 10px
    .register-link
      display: inline-block
      vertical-align: middle
      padding-left: 13px
      margin-top: 4px
    .message
      color: #AAA
      text-shadow: black 1px 1px 1px
      line-height: 13px
      font-size: 13px
      font-weight: normal
      background-position: left center
      background-repeat: no-repeat
      padding-bottom: 15px
      padding-right: 10px
      padding-left: 40px
      padding-top: 5px
    .submit
      margin-top: 6px
    .start-over
      display: inline-block
      float: right
      position: relative
      top: 14px
    .owner-wrapper
      float: right
      line-height: 30px
      margin-top: 6px
      cursor: pointer
      *
        display: inline-block
        vertical-align: middle
  &.yesno
    .header
      .icon, .text
        display: inline-block
        vertical-align: middle
    .body
      padding: 15px 27px 15px 27px
    .element-container
      text-align: left
    .message
      padding-bottom: 10px
      font-size: 14px
      line-height: 18px
    .yes
      margin-right: 15px
    &.onlyno .yes
      display: none
    .no
      float: right
    &.onlyyes .no
      display: none
  &.maintenance
    line-height: 18px

.notice .image-dropdown-option .tagline
  display: inline
  color: #888

/* Buttons and Toolbars */

.bar
  position: fixed
  height: 40px
  left: 0px
  right: 0px
  bottom: 0px
  z-index: 100000
  font-size: 12px
  text-shadow: 0 1px 0 #000
  color: #FFFFFF
  .background-rgba-fix
    /* Fallback for web browsers that doesn't support RGBa */
    background: rgb(0, 0, 0)
    /* RGBa with 0.6 opacity */
    background: rgba(0, 0, 0, 0.5)
  &.inline
    position: relative
    bottom: auto
    top: 0px
    white-space: nowrap
    .background-rgba-fix
      background: none
    >
      .left, .right
        display: inline-block
        position: relative
        padding-left: 0px
        padding-right: 0px
        border-spacing: 0px 0px
  >
    .left
      display: table
      position: absolute
      margin-right: auto
      padding-left: 15px
      bottom: 0px
      width: 100%
      height: 100%
      border-spacing: 15px 0px
    .right
      display: table
      position: absolute
      margin-left: auto
      padding-right: 15px
      right: 0px
      height: 100%
      border-spacing: 15px 0px
  .button
    display: table-cell
    vertical-align: middle
    position: relative
    &.text
      padding-left: 10px
      padding-right: 10px
    .text
      padding-right: 5px
      display: inline-block
      vertical-align: middle
    &.toggle
      /* Fallback for web browsers that don't support RGBa */
      background: rgb(0, 0, 0)
      /* RGBa with 0.6 opacity */
      background: rgba(0, 0, 0, 0.6)
      &.notoggle
        background: none
    .image
      padding-left: 5px
      padding-right: 5px
      background-position: center center
      background-repeat: no-repeat
      width: 40px
      height: 40px
      display: inline-block
      vertical-align: middle
      position: relative
  .cta-button.small
    height: 40px
    a, span
      height: 40px
  .button
    &.settings, &.map, &.register, &.corkurl
      height: 40px
    &.chat, &.warning
      width: 40px
    &.chat
      > .wrapper
        width: 50px
        height: 40px
        display: inline-block
        vertical-align: middle
        position: relative
      .unread-message-count
        position: absolute
        right: 5px
        top: 5px
        font-size: 10px
        font-weight: bold
        text-align: center
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5)
        background: red
        /*padding-top: 1px;
         *padding-left: 1px;
         *padding-right: 2px;
         *padding-bottom: 2px;
         */
        padding: 2px
        line-height: 9px
        -webkit-border-radius: 10px
        -moz-border-radius: 10px
        -ms-border-radius: 10px
        border-radius: 10px
        border: solid 2px white
        -webkit-box-shadow: 0px 0px 6px black
        -moz-box-shadow: 0px 0px 6px black
        box-shadow: 0px 0px 6px black
    &.settings .user-icon
      margin-top: 8px
      margin-left: 8px
    &.ok .image, &.notok .image
      background-position: center center
      background-repeat: no-repeat
    &.upload
      padding: 0px
      .wrapper
        width: 50px
        height: 40px
        background-position: center center
        background-repeat: no-repeat
      input[type=file]
        opacity: 0
        -moz-opacity: 0
        filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0)
        width: 50px
        height: 40px
        cursor: pointer

/* Have a second background element that sits in the background so that
 *   overflow doesn't get clipped by the filter tag in IE. See ie.sass.
 *
 *   From here:
 *   http://stackoverflow.com/questions/2756851/how-do-i-stop-internet-explorers-propriety-gradient-filter-from-cutting-off-con
 */

/*.bar .button.are-you-sure {
 *	padding-left: 0px;
 *	padding-right: 0px;
 *}
 */

/* Bit of a hack to make an image button out of a text button.
 * We do this because we have to style a file input field to
 * look like a custom button. */

/* Popup classes. */

.popup
  display: block
  position: absolute
  bottom: 55px
  /* Some popups animate and move with items, especially
   * the Ribbon. Let's make sure it's 3d accelerated if
   * possible. */
  -webkit-transform: translateZ(0)
  -moz-transform: translateZ(0)
  transform: translateZ(0)
  &.left, &.right
    /* JS will position it correctly. */
  > .content
    background: rgb(0, 0, 0)
    background: rgba(0, 0, 0, 0.8)
    padding: 10px
    .message
      white-space: nowrap
      line-height: 14px
  .tail
    position: absolute
    left: 0px
    width: 0px
    height: 0px
    margin-left: auto
    margin-right: auto
    border-color: black transparent transparent transparent
    /* Backup for IE. */
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent
    border-style: solid
    border-width: 10px
    border-bottom-width: 0px
  .options li
    font-size: 14px
    text-shadow: 1px 1px 1px black
    .wrapper
      margin-top: 4px
      margin-bottom: 3px
      padding-top: 5px
      padding-bottom: 7px
      padding-left: 8px
      padding-right: 8px
      min-width: 100px
      white-space: nowrap
    &.clickable .wrapper:hover
      background-color: rgb(0, 0, 0)
      background-color: rgba(0, 0, 0, 0.55)
      cursor: pointer
      -webkit-border-radius: 5px
      -moz-border-radius: 5px
      -ms-border-radius: 5px
      border-radius: 5px
  &.settings li.selected
    background-color: rgb(0, 0, 0)
    background-color: rgba(0, 0, 0, 0.55)
    cursor: pointer
  .options li
    &.image-li .wrapper
      padding-left: 0px
      padding-top: 4px
      padding-bottom: 4px
      margin-bottom: 0px
    .image-div
      height: 18px
      line-height: 18px
      background-position: left center
      background-repeat: no-repeat
      padding-left: 25px
    &.share-button:last-child
      margin-bottom: 3px
    &.separator
      border-bottom: solid 1px #999
      padding-top: 5px
      .wrapper
        display: none
  &.settings
    cursor: default
    > .content
      padding-top: 5px
      padding-bottom: 10px
      min-width: 193px
    li.board-select .wrapper
      padding: 1px 0px 3px 0px
      margin: 0px


/* NOTE: The next two are here to get attention, but are
 * used specifically as markers for javascript. */

/* Settings popup. */

.popup
  &.settings
    li
      &.logout .wrapper:hover
        background: rgb(170, 0, 0)
        background: rgba(170, 0, 0, 0.55)
      &.user
        font-weight: bold
        border-bottom: solid 1px #999
        margin-bottom: 7px
        .wrapper
          padding-left: 1px
          padding-right: 1px
          margin-bottom: 4px
    .message
      padding-top: 5px
      padding-bottom: 5px
  &.note-color
    white-space: nowrap
    > .content
      padding: 7px
    .color
      height: 40px
      width: 40px
      display: inline-block
    .color-wrapper
      display: inline-block
      &:hover
        background: rgb(0, 0, 0)
        background: rgba(0, 0, 0, 0.4)
        -webkit-border-radius: 5px
        -moz-border-radius: 5px
        -ms-border-radius: 5px
        border-radius: 5px
    .color:hover
      cursor: pointer
  &.edit
    bottom: auto
    z-index: 2
    /* Needs to be above the board. */
    > .content
      height: 40px
      padding: 0px 3px 0px 3px
    .tail
      left: 20px
      margin-left: 0px
      /* Added after scaling code. */
      visibility: hidden
    .bar .button
      &.ok .image, &.notok .image
        width: 30px

/* Note color popup. */

/* Ribbons. A special version of popup. */

/* Reaching too deep? */

/* Browser zoom message. */

.browser-zoom-message-wrapper
  display: block
  position: absolute
  height: 100%
  left: 50%
  top: 0px
  .message
    display: block
    position: relative
    left: -50%
    top: 24px
    background: #A00
    border: solid 1px black
    border: solid 1px rgba(0, 0, 0, 0.7)
    padding: 5px
    -webkit-border-radius: 6px
    -moz-border-radius: 6px
    -ms-border-radius: 6px
    border-radius: 6px
    font-weight: bold
    text-align: center
    white-space: nowrap
    color: #FFF
    z-index: 100000

/* Elements for the zoom poller. */

/* Element positioned in pixels will move as browser zooms. */

.browser-zoom-moving-element
  top: -100px
  width: 10px
  height: 10px
  background: red
  position: absolute

/* Element positioned with percentages will stay fixed to the window. */

.browser-zoom-fixed-element
  top: -100px
  left: 10%
  width: 10px
  height: 10px
  background: blue
  position: absolute

.bar
  .button
    &:hover
      cursor: pointer
      /* Fallback for web browsers that doesn't support RGBa */
      background: rgb(0, 0, 0) !important
      /* RGBa with 0.6 opacity */
      background: rgba(0, 0, 0, 0.6) !important
    &.nohover:hover
      background: none !important
  .contact:hover
    text-decoration: underline
  .corkidentifier
    font-size: 15px
    a
      display: inline-block
      vertical-align: middle
      position: relative
      top: -1px

/* Dialogs. Like popups, but not quite. From an older time. */

.dialog
  position: fixed
  bottom: 40px
  /* Fallback for web browsers that don't support RGBa */
  background: rgb(0, 0, 0)
  /* RGBa with 0.6 opacity */
  background: rgba(0, 0, 0, 0.8)
  color: white
  font-size: 12px
  text-shadow: black 0px 1px 0px
  padding: 15px
  /* Note: For Internet Explorer, dialogs need to be below the veil. */
  z-index: 100000
  &.placement-left
    left: 0px
    -webkit-border-top-right-radius: 6px
    -moz-border-radius-topright: 6px
    -ms-border-top-right-radius: 6px
    border-top-right-radius: 6px
  &.placement-right
    right: 0px
    -webkit-border-top-left-radius: 6px
    -moz-border-radius-topleft: 6px
    -ms-border-top-left-radius: 6px
    border-top-left-radius: 6px
  &.placement-full
    left: 0px
    right: 0px
    -webkit-border-radius: 0px
    -moz-border-radius: 0px
    -ms-border-radius: 0px
    border-radius: 0px
  &.placement-floating
    -webkit-border-top-left-radius: 6px
    -moz-border-radius-topleft: 6px
    -ms-border-top-left-radius: 6px
    border-top-left-radius: 6px
    -webkit-border-top-left-radius: 6px
    -moz-border-radius-topright: 6px
    -ms-border-top-right-radius: 6px
    border-top-right-radius: 6px
  &.align-center
    text-align: center
  .remove
    position: absolute
    background: url("../images/dialog/remove.png") no-repeat
    right: 3px
    top: 6px
    width: 10px
    height: 10px
    z-index: 1001
    cursor: pointer
    opacity: 0.35
    -moz-opacity: 0.35
  h1
    font-size: 40px
    font-weight: normal
    text-shadow: 0 2px 0 #000
  h2
    font-size: 14px
    font-weight: bold
    text-shadow: 0 1px 0 #000
  p
    padding-left: 10px
    padding-right: 10px
    padding-top: 10px
    /*padding-bottom: 20px; */
  .cancel
    color: #BBBBBB
  &.login
    min-width: 300px
    min-height: 50px
  &.read-only
    font-size: 13px
    cursor: pointer
    a
      color: #BBB
  &.warning
    height: 220px
    width: 350px
    text-shadow: 0 1px 0 #000
    .cancel
      padding-left: 10px
  &.corkurl
    padding-right: 25px
    .secondary
      color: #444
      a
        color: #BBB
        &.copy-to-clipboard
          visibility: hidden
          &.hover
            color: #BBB
            text-decoration: underline

/*.dialog.login .fb-button .login-frame {
 *	width: 69px;
 *	height: 22px;
 *	overflow: hidden;
 *} */

/* Warning styles. */

/* Special hover class for ZeroClipboard. */

/* Chat styles. */

.popup.chat
  width: 300px
  > .content
    padding: 0px
  .chat-container
    cursor: auto
    padding-top: 5px
  .input-container
    padding-bottom: 5px
  .message-container
    display: block
    .wrapper
      padding-left: 10px
      padding-right: 10px
  .input-container .wrapper
    background: #3D3C3C
    /* Old browsers */
    background: -moz-linear-gradient(left, #212121 0%, #3d3c3c 20%, #3d3c3c 80%, #212121 100%)
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #212121), color-stop(20%, #3d3c3c), color-stop(80%, #3d3c3c), color-stop(100%, #212121))
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, #212121 0%, #3d3c3c 20%, #3d3c3c 80%, #212121 100%)
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, #212121 0%, #3d3c3c 20%, #3d3c3c 80%, #212121 100%)
    /* Opera 11.10+ */
    background: -ms-linear-gradient(left, #212121 0%, #3d3c3c 20%, #3d3c3c 80%, #212121 100%)
    /* IE10+ */
    background: linear-gradient(to right, #212121 0%, #3d3c3c 20%, #3d3c3c 80%, #212121 100%)
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#212121', endColorstr='#212121',GradientType=1 )
    /* IE6-9 */
    padding-right: 0px
  .message-container
    .input, .text
      display: inline-block
      vertical-align: middle
    .cta-button
      height: 33px
      a
        height: 33px
        span
          height: 33px
    .user-icon
      display: inline-block
      vertical-align: top
      position: relative
      top: 4px
      left: 3px
    .text
      color: #FFF
      width: 230px
      font-size: 13px
      margin-left: 10px
      text-shadow: 1px 1px 1px black
    .input
      color: #FFF
      width: 240px
      font-size: 13px
      margin-left: 10px
      text-shadow: 1px 1px 1px black
      background: none
      border: none
      height: 32px
    .text
      padding-top: 9px
      padding-bottom: 9px
      text-shadow: 2px 2px 2px black
  .log-wrapper
    margin-bottom: 4px
    .antiscroll-scrollbar-vertical
      -webkit-box-shadow: none
      -moz-box-shadow: none
      box-shadow: none
      border: solid 1px #555
      right: 4px
      background: #999
      background: rgba(200, 200, 200, 0.25)
      width: 6px
  .log
    max-height: 300px
    word-wrap: break-word
    overflow-y: auto
    overflow-x: hidden
    line-height: 14px
    /* For slightly better readability. */
    background: rgba(0, 0, 0, 0.1)
    .message
      margin-bottom: 1px
  .display-name
    font-weight: bold
    color: #e7dd86
    &.self
      color: #777

/* Minimap styles. */

.minimap
  background: #000
  border-radius: 6px
  overflow: hidden
  position: relative
  .center
    z-index: 1
  .drag-box
    position: absolute
  .bounds
    position: absolute
    z-index: 1
    border: dotted 1px #FFFFFF
    border-radius: 2px

/* Ensure drag window is above center. */

.minimap-item
  position: absolute
  .wrapper
    /* Absolutely position to the edges so borders don't add height/width. */
    position: absolute
    left: 0px
    right: 0px
    top: 0px
    bottom: 0px
  &.post_it
    .wrapper
      border: solid 1px black
    &.yellow .wrapper
      background: #f2edab
    &.pink .wrapper
      background: #f2acb6
    &.blue .wrapper
      background: #9bbdef
    &.purple .wrapper
      background: #d688ec
    &.green .wrapper
      background: #7de386
    &.white .wrapper
      background: #ffffff
    &.orange .wrapper
      background: #f0ca99
    &.gray .wrapper
      background: #bfbfbf
  &.photo .wrapper
    background: #000000
    border: solid 1px white
    border-bottom: solid 3px white

.expires-container
  position: absolute
  left: 0px
  top: -100px
  width: 100%
  z-index: 1000
  text-align: center
  min-height: 24px

/* Expires timer. */

.expires-wrapper
  position: relative
  top: 100px
  display: inline-block
  padding-left: 25px
  padding-right: 25px
  min-width: 40%
  max-width: 60%
  margin-right: auto
  margin-left: auto
  line-height: 24px
  font-size: 11px
  text-align: center
  color: #373737
  text-shadow: 1px 1px 1px #FFF
  background: #F5F5F1
  box-shadow: 0px 0px 7px #000
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
  .timer
    font-weight: bold
    color: #000
    white-space: nowrap
    .unit
      color: #888
      font-weight: normal
  a
    white-space: nowrap
    font-weight: normal
    color: #0064bf
    text-decoration: underline

/* For posting into post-its. */

.offscreen
  position: absolute
  left: -99999px
  top: -99999px
