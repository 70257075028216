/* Note: Not ALL embedded images are here, but ones especially prone to reuse are.

/* Logo */

.ei-app-logo
  background-image: url("../images/embed/logos/noteapp.png")

/* Toolbar buttons. */

.ei-lines
  background-image: url("../images/embed/buttons/show_lines.png")

.ei-scope
  background-image: url("../images/embed/buttons/scope.png")

.ei-trash
  background-image: url("../images/embed/buttons/trash.png")

.ei-ok
  background-image: url("../images/embed/buttons/ok.png")

.ei-notok
  background-image: url("../images/embed/buttons/notok.png")

.ei-gear
  background-image: url("../images/embed/buttons/gear-hover.png")

.ei-map
  background-image: url("../images/embed/buttons/map.png")

.ei-locked
  background-image: url("../images/embed/buttons/lock.locked.png")

.ei-unlocked
  background-image: url("../images/embed/buttons/lock.unlocked.png")

.ei-chat
  background-image: url("../images/embed/buttons/chat-off.png")

.ei-chat-activated
  background-image: url("../images/embed/buttons/chat-on.png")

.ei-chat-error
  background-image: url("../images/embed/buttons/chat-bad.png")

.ei-chat-connecting
  background-image: url("../images/embed/buttons/chat-connecting.png")

.ei-user
  background-image: url("../images/embed/buttons/user.png")

.ei-pin
  background-image: url("../images/embed/buttons/pin.png")

.ei-help
  background-image: url("../images/embed/buttons/help.png")

.ei-warning
  background-image: url("../images/embed/buttons/warning.png")

.ei-speech-bubble
  background-image: url("../images/embed/buttons/speech-bubble.png")

.ei-comment-bubble
  background-image: url("../images/embed/buttons/comments.png")

.ei-cloud-upload
  background-image: url("../images/embed/buttons/cloud-upload.png")

.ei-popup-share
  background-image: url("../images/embed/buttons/popup/share.png")

.ei-popup-send
  background-image: url("../images/embed/buttons/popup/send.png")

.ei-popup-gear
  background-image: url("../images/embed/buttons/popup/gear.png")

.ei-popup-upgrade
  background-image: url("../images/embed/buttons/popup/upgrade.png")

.ei-popup-edit
  background-image: url("../images/embed/buttons/popup/edit.png")

.ei-popup-keys
  background-image: url("../images/embed/buttons/popup/keys.png")

.ei-popup-team
  background-image: url("../images/embed/buttons/popup/team.png")

/* Modals */

.ei-green-checkmark
  background-image: url("../images/embed/modals/accepted_20.png")

.ei-pushpin
  background-image: url("../images/embed/pushpin.png")

.ei-remove
  background-image: url("../images/embed/modals/remove.png")

.ei-copy
  background-image: url("../images/embed/modals/copy.png")

.ei-export
  background-image: url("../images/embed/modals/export.png")

/* Note colors */

.ei-note-white
  /* Icon only; does not have corresponding note. */
  background-image: url("../images/embed/buttons/note-color/note-white.png")

.ei-note-white-noalpha
  background-image: url("../images/embed/buttons/note-color/note-white-noalpha.png")

.ei-note-yellow
  background-image: url("../images/embed/buttons/note-color/note-yellow.png")

.ei-note-pink
  background-image: url("../images/embed/buttons/note-color/note-pink.png")

.ei-note-blue
  background-image: url("../images/embed/buttons/note-color/note-blue.png")

.ei-note-purple
  background-image: url("../images/embed/buttons/note-color/note-purple.png")

.ei-note-green
  background-image: url("../images/embed/buttons/note-color/note-green.png")

.ei-note-orange
  background-image: url("../images/embed/buttons/note-color/note-orange.png")

.ei-note-gray
  background-image: url("../images/embed/buttons/note-color/note-gray.png")

/* Item specfic images. */

.ei-item-move-grip
  background-image: url("../images/embed/items/move-grip.png")

.ei-item-delete-attachments
  background-image: url("../images/embed/items/delete-attachments.png")

.ei-item-remove-small
  background-image: url("../images/embed/items/remove.png")

.ei-bottom-left-shadow
  background-image: url("../images/embed/items/bottom-left-shadow.png")

.ei-bottom-right-shadow
  background-image: url("../images/embed/items/bottom-right-shadow.png")

/* File icons. */

.ei-file-icon-default
  background-image: url("../images/embed/file-types/default_24.png")

.ei-file-icon-pdf
  background-image: url("../images/embed/file-types/pdf_24.png")

.ei-file-icon-psd
  background-image: url("../images/embed/file-types/psd_24.png")

.ei-file-icon-archive
  background-image: url("../images/embed/file-types/rar_24.png")

.ei-file-icon-text
  background-image: url("../images/embed/file-types/rtf_24.png")

.ei-file-icon-video
  background-image: url("../images/embed/file-types/mp4_24.png")

.ei-file-icon-audio
  background-image: url("../images/embed/file-types/aac_24.png")

.ei-file-icon-image
  background-image: url("../images/embed/file-types/bmp_24.png")

.ei-file-icon-command
  background-image: url("../images/embed/file-types/cmd_24.png")

.ei-file-icon-code
  background-image: url("../images/embed/file-types/log_24.png")

.ei-file-icon-document
  background-image: url("../images/embed/file-types/doc_24.png")

.ei-file-icon-presentation
  background-image: url("../images/embed/file-types/ppt_24.png")

.ei-file-icon-spreadsheet
  background-image: url("../images/embed/file-types/xls_24.png")

.ei-cvv-glyph, .recurly .cvv_glyph
  background-image: url("../images/recurly/cvv-glyph.png")
