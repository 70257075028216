.bar .button.chat-status {
  white-space: nowrap;
  cursor: auto;
}

.chat-status .relative-div {
  position: relative;
  overflow-y: hidden;
}

.chat-status * {
  white-space: nowrap;
}

.chat-status .icon-wrapper {
  position: relative;
  top: 0px;
  left: 0px;
}

.chat-status .message-wrapper {
  height: 40px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.chat-status .user-icon {
  display: inline-block;
  margin: 8px;
}

.chat-status .distinguished-wrapper,
.chat-status .anon-wrapper {
  display: inline-block;
  vertical-align: bottom;
  /*height: 40px;*/
}

.chat-status .icon-wrapper {
  max-width: 200px;
  overflow: hidden;
}

.chat-status .anon-count,
.chat-status .message {
  display: inline-block;
  margin-top: 9px;
  margin-bottom: 7px;
  vertical-align: top;
  line-height: 24px;
  text-shadow: 1px 1px 1px black;
}

.chat-status .message {
  text-overflow: ellipsis;
  max-width: 300px;
  overflow-x: hidden;
}
