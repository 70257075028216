.hotkeys-modal
  .body
    padding-top: 23px
    width: 455px
  .keyboard-key
    display: inline-block
    font-family: "Courier New", Courier, monospace
    font-weight: bold
    background: #DDD
    padding: 2px 4px
    text-align: center
    text-shadow: none
    border: solid 1px #575757
    min-width: 13px
    line-height: 15px
    -webkit-border-radius: 3px
    -moz-border-radius: 3px
    -ms-border-radius: 3px
    border-radius: 3px
    vertical-align: middle
  .plus, .equals, .slash
    vertical-align: middle
    font-size: 12px
    display: inline-block
    color: #878787
    text-shadow: none
    margin-left: 15px
    margin-right: 15px
  .text
    vertical-align: middle
    font-size: 13px
    display: inline-block
  .row
    text-align: left
    margin-bottom: 3px

/*.hotkeys-modal .slash {
 *	margin-left: 2px;
 *	margin-right: 2px;
 *}
