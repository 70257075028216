/* Bar which is placed behind the progress */
@-webkit-keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.ui-progress-bar {
  border-radius: 25px;

  background-color: #f9f9f9;
  color: #fff;
  box-shadow: inset 0 1px 1px rgba(100, 100, 100, 0.1);
  box-sizing: initial;
  color: #fff;
  font-size: 11px;
  font-size: 0.6875rem;
  height: 12px;
  line-height: 1.182;
  position: relative;
  text-align: center;
  width: 100%;

  .ui-progress {
    border-radius: 25px;

    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: auto;

    &:after {
      background-size: 50px 50px;
      bottom: 0;
      content: "";
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      background-size: 50px 50px;
      background-image: -webkit-gradient(
        linear,
        0 0,
        100% 100%,
        color-stop(0.25, rgba(255, 255, 255, 0.2)),
        color-stop(0.25, transparent),
        color-stop(0.5, transparent),
        color-stop(0.5, rgba(255, 255, 255, 0.2)),
        color-stop(0.75, rgba(255, 255, 255, 0.2)),
        color-stop(0.75, transparent),
        to(transparent)
      );
      background-image: -moz-linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
      );
      animation: move 2s linear infinite;
    }

    span.ui-label {
      font-size: 13px;
      position: absolute;
      right: 0;
      line-height: 23px;
      padding-right: 12px;
      color: rgba(0, 0, 0, 0.6);
      text-shadow: rgba(255, 255, 255, 0.45) 0 1px 0px;
      white-space: nowrap;

      b {
        font-weight: bold;
      }
    }
  }

  &.blue .ui-progress {
    background-color: #339bb9 !important;
    border: 1px solid #287a91;
  }

  &.error .ui-progress {
    background-color: #c43c35 !important;
    border: 1px solid #9c302a;
  }

  &.warning .ui-progress {
    background-color: #d9b31a !important;
    border: 1px solid #ab8d15;
  }

  &.success .ui-progress {
    background-color: #57a957 !important;
    border: 1px solid #458845;
  }
}
